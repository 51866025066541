@import url(https://fonts.googleapis.com/css?family=Kanit);
@import url(https://fonts.googleapis.com/css?family=Comfortaa);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-brand {
  font-family: 'Kanit', sans-serif;
  font-size: 3em;
}

.navbar-brand span{
  font-weight: 900;
  color: red;
}
.general-information {
  font-size: .7em;
}
.portrait {
  width: 100%;
}

h4.text-muted {
  font-family: 'Comfortaa', cursive;
}
#content-sidebar {
  z-index: 2;
}
#content-section {
  
  margin-top: 20px;
  z-index: 1;
}
@media(min-width: 768px) {
  #content-section {
    margin-left: -10px;
  }
}



html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body > div {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
body{
  background-image: -webkit-linear-gradient(315deg, #007c91 0%, #338a3e 100%);
  background-image: linear-gradient(135deg, #007c91 0%, #338a3e 100%);
}
#root {
  padding-top: 10px;
  margin-bottom: 100px;
}
#main-content {
  margin-top: -22px
}
.context {
    width: 100%;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}



.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: animate 25s linear infinite;
            animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
}



@-webkit-keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
