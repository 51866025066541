@import url('https://fonts.googleapis.com/css?family=Comfortaa');

.portrait {
  width: 100%;
}

h4.text-muted {
  font-family: 'Comfortaa', cursive;
}
#content-sidebar {
  z-index: 2;
}
#content-section {
  
  margin-top: 20px;
  z-index: 1;
}
@media(min-width: 768px) {
  #content-section {
    margin-left: -10px;
  }
}
